<template>
  <div class="confirmation">
    <component :is="template"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwConfirmation',

  components: {
    'cw-car-loan-confirmation': () => import('@fi/pages/steps/ConfirmationCarLoan'),
    'cw-continuous-loan-confirmation': () => import('@fi/pages/steps/ConfirmationContinuousLoan'),
    'cw-instalment-loan-confirmation': () => import('@fi/pages/steps/ConfirmationInstalmentLoan'),
  },

  data: () => ({
    template: '',
  }),

  computed: {
    ...mapGetters({
      offer: 'application/getSelectedOffer',
    }),
  },

  async created() {
    const { type } = this.offer;

    const offerTypes = {
      continuousLoan: 'cw-continuous-loan-confirmation',
      fixedPeriodContinuousLoan: 'cw-continuous-loan-confirmation',
      instalmentLoan: 'cw-instalment-loan-confirmation',
      carInstalmentLoan: 'cw-car-loan-confirmation',
    };

    this.template = offerTypes[type];
    /**
     * Set the purchase property to true to push the EEC purchase event on the complete page
     * This is used to prevent transaction duplicates in GA.
     */
    this.setPurchase(true);

    await this.listTerms();
  },

  methods: {
    ...mapActions({
      setPurchase: 'application/setPurchase',
      listTerms: 'application/listTerms',
    }),
  },
};
</script>

